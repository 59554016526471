import books from '../../assets/home_images/books.png';
import film from '../../assets/home_images/film.png';
import bowling from '../../assets/home_images/bowling.png';
import camp from '../../assets/home_images/cottage.png';
import deskovky from '../../assets/home_images/deskovky.png';
import music from '../../assets/home_images/musicquiz.png';
import dating from '../../assets/home_images/speeddating.png';
import svarak from '../../assets/home_images/svarak.png';

export const links = [
	{
		id: 1,
		background: books,
		CName: 'knizni-card',
		title: 'Knižní diskuzní Klub',
	},
	{
		id: 2,
		background: deskovky,
		CName: 'deskovky-card',
		title: 'Deskovky',
	},
	{
		id: 3,
		background: bowling,
		CName: 'bowling-card',
		title: 'Bowling',
	},
	{
		id: 4,
		background: music,
		CName: 'music-card',
		title: 'Music Kvíz',
	},
	{
		id: 5,
		background: film,
		CName: 'film-card',
		title: 'Filmový diskuzní klub',
	},
	{
		id: 6,
		background: camp,
		CName: 'camp-card',
		title: 'Lesbotoč Camp',
	},
	{
		id: 7,
		background: svarak,
		CName: 'svarak-card',
		title: 'Tour de Svařáček',
	},
	{
		id: 8,
		background: dating,
		CName: 'dating-card',
		title: 'Speed Dating',
	},
];
