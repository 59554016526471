import React, { useState } from 'react'

export default function RainbowCloud({ width = 10, height = 10 }) {

	const [isHovered, setIsHovered] = useState(false);

	return (
		<div
			className={`rainbow-cloud-container ${isHovered ? 'hovered' : ''}`}
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
		>
			<svg xmlns="http://www.w3.org/2000/svg" id="flat" viewBox="0 0 960 960" width={width} height={height}>
				<path d="m743.28,695.42h-38.79c0-73.25-22.7-140.38-63.91-189.04-28.54-33.69-79.57-73.86-160.58-73.86s-132.04,40.17-160.58,73.86c-41.21,48.66-63.91,115.79-63.91,189.04h-38.79c0-81.95,26.79-158.53,73.62-213.82,23.19-27.38,50.69-48.75,81.72-63.5,32.64-15.51,69.62-24.38,107.94-24.38s75.3,8.87,107.94,24.38c31.03,14.75,58.53,36.12,81.72,63.5,46.83,55.29,73.62,131.87,73.62,213.82Z" fill="#a3d6ff"/>
				<path d="m780.08,695.42h-38.8c0-81.95-25.79-157.53-72.62-212.82-23.19-27.38-50.69-48.75-81.72-63.5-32.64-15.51-68.62-23.38-106.94-23.38s-74.3,7.87-106.94,23.38c-31.03,14.75-58.53,36.12-81.72,63.5-46.83,55.29-72.62,130.87-72.62,212.82h-38.8c0-182.28,119.55-338.49,300.08-338.49s300.08,156.21,300.08,338.49Z" fill="#6aac96"/>
				<path d="m816.88,695.42h-38.8c0-182.28-117.55-336.49-298.08-336.49s-298.08,154.21-298.08,336.49h-38.8c0-99.35,31.99-193.82,90.06-262.39,30-35.41,66.76-63.13,107.29-82.39,42.59-20.25,90.19-30.51,139.53-30.51s96.94,10.26,139.53,30.51c40.53,19.26,77.29,46.98,107.29,82.39,58.07,68.57,90.06,163.04,90.06,262.39Z" fill="#feca7a"/>
				<path d="m851.67,695.42h-36.79c0-99.35-31.99-191.82-90.06-260.39-30-35.41-65.76-63.13-106.29-82.39-42.59-20.25-89.19-30.51-138.53-30.51s-95.94,10.26-138.53,30.51c-40.53,19.26-76.29,46.98-106.29,82.39-58.07,68.57-90.06,161.04-90.06,260.39h-36.79c0-108.05,35.08-208.97,98.78-284.17,68.77-81.2,165.69-125.92,272.89-125.92s204.12,44.72,272.89,125.92c63.7,75.2,98.78,176.12,98.78,284.17Z" fill="#fe7e61"/>
				<path d="m279.851,676.541c-15.846-2.363-32.654,1.163-47.633-4.52-14.267-5.413-24.212-18.157-36.249-27.536-27.564-21.479-68.443-24.043-98.475-6.177-30.032,17.866-47.286,52.542-41.565,87.014h276.297c-6.593-23.746-28-45.146-52.375-48.781Z" fill="#e0eded"/>
				<path d="m651.017,698.749c7.797-3.428,16.075-2.155,23.21-6.807,7.288-4.751,10.656-13.469,15.114-20.939,9.004-15.086,24.081-26.427,41.072-30.894s35.697-2.008,50.956,6.7c16.099,9.187,28.784,25.073,46.846,29.239,11.98,2.764,24.607-.141,36.761,1.713,23.07,3.519,41.502,24.266,40.108,47.561h-276.236c5.165-14.7,10.615-21.494,22.168-26.573Z" fill="#e0eded"/>
			</svg>
		</div>
  )
}
