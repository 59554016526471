export const navbarlinks = [
  {
    id: 1,
    to: '/kalendar',
    name: 'Kalendář',
  },
  {
    id: 2,
    to: '/galerie',
    name: 'Galerie',
  },
  {
    id: 3,
    to: '/about',
    name: 'O nás',
  },
  {
    id: 4,
    to: '/kontakt',
    name: 'Kontakt',
  },
];
