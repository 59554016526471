import React from 'react'

export default function ContactIllustration({ width, height }) {
	return (
		<svg 
			xmlns="http://www.w3.org/2000/svg" 
			width={width}
			height={height}
			viewBox="0 0 865.76 682.89">
			<g>
				<g>
					<path d="M59.25,219.88s14.62-12.67,40.93,25.34c0,0,13.64,18.51,24.36,28.26,0,0,26.31,19.49,21.44,31.18l-7.8,1.95-46.77-38.98-28.78-25.69s-8.25-8.41-3.37-22.06Z" fill="#231f20"></path>
					<path d="M138.05,307.15l-46.97-39.14-28.79-25.7c-.11-.11-8.43-8.82-3.51-22.6l.04-.12,.1-.09c.13-.11,3.27-2.77,9.22-2.02,6.72,.85,17.81,6.3,32.45,27.45,.13,.17,13.73,18.57,24.29,28.17,1.05,.77,26.53,19.83,21.56,31.75l-.1,.23-8.29,2.07ZM59.67,220.18c-4.6,13.09,3.23,21.32,3.31,21.4l28.76,25.67,46.57,38.8,7.3-1.82c4.23-11.23-21.11-30.17-21.37-30.36-10.67-9.7-24.33-28.18-24.47-28.36-14.41-20.81-25.2-26.19-31.71-27.03-4.96-.64-7.84,1.29-8.39,1.7Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M136.23,404.05s53.59-57.49,66.26-90.62c0,0,16.57,28.26,15.59,49.7l-21.44,34.11-60.42,6.82Z" fill="#feebdd"></path>
					<path d="M134.94,404.7l.92-.99c.54-.57,53.67-57.8,66.16-90.46l.37-.97,.53,.9c.17,.28,16.63,28.63,15.66,49.97v.13s-21.64,34.42-21.64,34.42l-.24,.03-61.76,6.97Zm67.63-90.11c-12.25,30.58-58.01,81.13-65.07,88.82l58.84-6.64,21.24-33.79c.79-18.89-12.24-43.39-15.02-48.38Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M202.24,303.23c-2.08,3.76-4.52,9.86-1.89,14.56l2.14-4.37s15.59,26.31,15.59,49.7c0,0,11.69-.97,16.57-8.77l3.9-7.8s-24.36-59.44-24.36-61.39-6.82-25.34-6.82-25.34c0,0-12.59,9.48-4.38,35.67,.81,2.57,.56,5.38-.74,7.74Z" fill="#ed7f71"></path>
					<path d="M217.58,363.67v-.54c0-20.7-12.47-44.02-15.06-48.63l-2.15,4.38-.47-.83c-2.67-4.79-.45-10.83,1.89-15.05,1.22-2.2,1.47-4.88,.7-7.34-8.22-26.23,4.03-35.82,4.56-36.22l.58-.44,.2,.69c.7,2.4,6.84,23.51,6.84,25.48,.16,1.75,14.64,37.56,24.32,61.2l.09,.21-4,8c-4.97,7.96-16.48,9-16.97,9.04l-.54,.05Zm-15.13-51.29l.47,.8c.16,.26,15.41,26.27,15.66,49.39,2.32-.31,11.55-1.92,15.64-8.47l3.77-7.55c-2.87-7.01-24.31-59.4-24.31-61.37,0-1.34-3.85-15.06-6.58-24.43-2.31,2.26-10.63,12.35-3.65,34.61,.85,2.73,.57,5.69-.78,8.13l-.44-.24,.44,.24c-1.7,3.07-4.14,8.68-2.32,13.17l2.1-4.28Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M216.57,245.94c5.39-.73,23.57,.11,33.48,41.54,1.11,4.64,1.97,9.34,2.63,14.07,.8,5.71,2.59,17.08,5.36,27.46,0,0,9.74,21.44-9.74,23.39h-8.77l-11.69-27.28-13.64-29.23s-11.29-34.85-.17-48.5c.64-.78,1.56-1.31,2.56-1.44Z" fill="#231f20"></path>
					<path d="M239.52,352.9h-.33l-11.82-27.59-13.64-29.22c-.49-1.49-11.27-35.32-.1-49.03,.73-.89,1.75-1.47,2.88-1.62h0c5.46-.74,24.03,.1,34.03,41.92,1.07,4.47,1.93,9.09,2.64,14.12,.82,5.81,2.59,17.09,5.34,27.4,.18,.37,4.89,10.93,.8,18.04-1.98,3.43-5.67,5.44-10.98,5.97h-8.82Zm.33-1h8.44c4.92-.49,8.36-2.34,10.16-5.47,3.84-6.67-.82-17.1-.87-17.21-2.79-10.44-4.58-21.77-5.4-27.6-.7-4.99-1.56-9.58-2.62-14.03-9.81-41.01-27.68-41.88-32.92-41.16h0c-.88,.12-1.67,.57-2.24,1.26-10.83,13.29,.15,47.68,.26,48.03l13.62,29.18,11.57,27Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M122.58,406.97l-19.49,1.95,1.95-89.65s-19.49-21.44-38-26.31c0,0,2.68,52.8-12.53,92.37-1.02,2.66-2.22,5.25-3.5,7.81-4.5,8.99-17.94,38.36-13.22,60.34,2.71,12.61,13.84,21.64,26.74,21.9l50.8,1.02,11.15-10.95,5.85-48.72-9.74-9.74Z" fill="#feebdd"></path>
					<path d="M115.54,476.89h-.21s-50.8-1.02-50.8-1.02c-13.27-.27-24.46-9.43-27.22-22.29-4.77-22.21,8.94-52.05,13.26-60.67,1.46-2.92,2.57-5.38,3.48-7.76,15-39.01,12.52-91.63,12.5-92.16l-.03-.68,.66,.17c18.44,4.85,38.05,26.24,38.25,26.46l.13,.15-1.94,89.28,19.16-1.92,10.08,10.08-5.9,49.14-11.42,11.22Zm-47.97-183.27c.24,6.35,1.55,55.11-12.59,91.88-.93,2.41-2.04,4.9-3.52,7.85-4.28,8.54-17.88,38.11-13.18,60.01,2.66,12.41,13.46,21.25,26.26,21.5l50.59,1.01,10.87-10.68,5.8-48.31-9.4-9.41-19.82,1.98,1.96-90.01c-1.73-1.87-19.8-20.96-36.97-25.84Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M67.04,292.96s0-10.96-3.29-23.02c-1.51-5.54-3.99-10.75-7.04-15.61-3.06-4.88-7.34-14.05-3.32-22.76l80.88,79.91s-12.73,15.63-29.26,15.61l.03-7.82s-16.57-19.49-38-26.31Z" fill="#ed7f71"></path>
					<path d="M105.05,327.59h-.53s.03-8.13,.03-8.13c-1.54-1.76-17.55-19.62-37.65-26.02l-.35-.11v-.37c0-.11-.04-11.04-3.27-22.89-1.39-5.1-3.74-10.31-6.98-15.47-3.03-4.83-7.49-14.26-3.35-23.24l.3-.65,81.71,80.73-.29,.35c-.13,.16-13.06,15.8-29.62,15.8Zm-37.51-34.99c21.16,6.88,37.72,26.16,37.89,26.35l.12,.14-.03,7.5c14.51-.27,26.11-12.82,28.08-15.08L53.57,232.44c-3.44,8.35,.73,17.09,3.57,21.63,3.29,5.25,5.68,10.54,7.1,15.74,2.93,10.76,3.26,20.77,3.3,22.79Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M59.25,219.88s-1.95,11.69,19.49,27.28c0,0,36.05,27.28,48.72,43.85,0,0,6.82,10.72,18.51,13.64,0,0-8.77,10.72-18.51,5.85l-60.42-52.62s-24.36-24.36-7.8-38Z" fill="#fff"></path>
					<path d="M132.68,312.26c-1.75,0-3.58-.38-5.44-1.31l-.1-.07-60.42-52.62c-.61-.61-14.34-14.49-13.72-27.34,.22-4.52,2.21-8.36,5.93-11.43l1.02-.84-.21,1.31c-.02,.11-1.58,11.62,19.29,26.8,.37,.28,36.26,27.52,48.83,43.95,.09,.14,6.88,10.62,18.24,13.46l.77,.19-.5,.61c-.07,.09-6.07,7.29-13.68,7.29Zm-4.94-2.18c7.93,3.92,15.32-3.03,17.32-5.15-11.27-3.21-17.95-13.54-18.02-13.65-12.46-16.29-48.24-43.45-48.6-43.72-17.71-12.88-19.65-23.1-19.74-26.54-2.94,2.74-4.52,6.08-4.7,9.94-.6,12.41,13.26,26.42,13.4,26.56l60.34,52.55Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M286.29,476.16l-138.16-1.93c-.55,0-.97,.48-.88,1.01,.28,1.68-.25,4.82-8.11,4.82h-18.63c-2.69,0-4.92-2.1-5.07-4.79-.66-12.2-1.65-44.87,5.03-64.31,2.03-5.91,7.65-9.83,13.9-9.83h20.35s26.31-15.59,52.62-16.57c0,0,45.8-23.39,85.75-5.85,0,0,16.57,66.26,15.59,67.24s-22.41,30.21-22.41,30.21Z" fill="#7ab6cb"></path>
					<path d="M139.15,480.56h-18.63c-2.96,0-5.4-2.31-5.56-5.26-.7-12.94-1.6-45.12,5.06-64.5,2.09-6.08,7.87-10.17,14.37-10.17h20.22c1.97-1.14,27.43-15.59,52.63-16.56,2.44-1.21,47.27-22.85,86.08-5.81l.22,.1,.06,.24c.04,.17,4.19,16.76,8.16,33.26,8.1,33.65,7.66,34.09,7.3,34.45-.78,.78-16.41,22.03-22.36,30.15l-.15,.21h-.26s-138.16-1.94-138.16-1.94c-.14,.02-.25,.08-.29,.13-.04,.05-.11,.15-.08,.3,.14,.83,.11,2.04-.81,3.12-1.28,1.51-3.9,2.27-7.79,2.27Zm-4.76-78.93c-6.08,0-11.47,3.81-13.42,9.49-6.61,19.21-5.7,51.24-5.01,64.12,.13,2.42,2.14,4.32,4.57,4.32h18.63c3.53,0,5.96-.66,7.03-1.92,.54-.63,.73-1.41,.58-2.31-.07-.4,.05-.81,.31-1.12,.27-.31,.62-.48,1.07-.48l137.9,1.93c2.03-2.77,20.43-27.84,22.21-29.94,.11-2.76-9.18-41.11-15.56-66.64-39.23-17.06-84.64,5.7-85.1,5.93l-.21,.05c-25.88,.96-52.12,16.34-52.38,16.5l-.12,.07h-20.49Z" fill="#231f20"></path>
				</g>
					<path d="M145,468.86c-.25,0-.46-.18-.5-.43-5.8-43.53,9.67-67.35,9.82-67.58,.15-.23,.46-.29,.69-.14,.23,.15,.29,.46,.14,.69-.15,.23-15.42,23.76-9.67,66.89,.04,.27-.16,.52-.43,.56-.02,0-.04,0-.07,0Z" fill="#231f20"></path>
					<path d="M248.29,421.12h-19.49c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h19.16l16.16-38.02c-44.32-2.48-55.34,19.1-56.23,21.03,1.95,8.4,9.44,14.11,9.52,14.17,.22,.17,.27,.48,.1,.7-.17,.22-.48,.27-.7,.1-.32-.24-7.97-6.06-9.93-14.91-.02-.1-.01-.2,.02-.29,.1-.25,10.42-24.67,57.99-21.75,.16,0,.31,.1,.39,.24,.09,.14,.1,.31,.03,.46l-16.57,38.98c-.08,.18-.26,.3-.46,.3Z" fill="#231f20"></path>
					<path d="M262.79,476.15s-.03,0-.05,0c-24.41-2.45-37.14-19.01-37.26-19.18-.17-.22-.12-.53,.1-.7,.22-.17,.54-.12,.7,.1,.12,.16,12.6,16.38,36.56,18.78,.27,.03,.48,.27,.45,.55-.03,.26-.24,.45-.5,.45Z" fill="#231f20"></path>
					<path d="M94.33,434.76c-.12,0-.24-.04-.33-.13-.21-.18-.23-.5-.04-.71,7.59-8.54,8.63-24.87,8.64-25.03,.02-.28,.25-.49,.53-.47,.28,.02,.49,.25,.47,.53-.04,.68-1.07,16.84-8.9,25.64-.1,.11-.24,.17-.37,.17Z" fill="#231f20"></path>
				<g>
					<path d="M367.68,350.31s-16.74,15.59-36.19,25.12c-11.92,5.84-25.62,6.93-38.38,3.29h0s-14.62,4.87-16.57,46.77c0,0-2.92,35.08,9.74,50.67l89.65,1.95s-2.92,18.51,16.57,18.51h22.41s6.82-.97,7.8-6.82h-6.82s.97-23.39,15.59-38.98l73.08-3.9v-53.88c0-6.48-2.23-12.78-6.41-17.73-2.81-3.33-6.74-6.76-12.11-9.27l-54.05-26.63-13.19-20.15-38.98,12.67-12.16,18.37Z" fill="#ed7f71"></path>
					<path d="M392.51,497.12c-6.03,0-10.58-1.75-13.53-5.19-4.11-4.81-3.8-11.37-3.6-13.33l-89.33-1.94-.15-.18c-12.65-15.57-9.97-49.59-9.85-51.03,1.94-41.75,16.3-47,16.91-47.21l.15-.05,.15,.04c12.78,3.65,26.29,2.5,38.03-3.26,18.67-9.15,35.06-24.11,36.02-25l12.22-18.46,.17-.05,39.34-12.79,13.32,20.35,53.93,26.56c4.75,2.22,8.88,5.38,12.27,9.39,4.21,4.98,6.52,11.39,6.52,18.05v54.36l-73.36,3.91c-13,13.99-15.03,34.47-15.29,37.99h6.88l-.1,.58c-1.02,6.14-8.15,7.22-8.22,7.23h-22.48Zm-105.97-21.45l89.99,1.96-.09,.56c-.01,.08-1.17,7.86,3.31,13.1,2.75,3.21,7.04,4.84,12.76,4.84h22.41s5.94-.92,7.18-5.82h-6.73l.02-.52c0-.24,1.15-23.75,15.73-39.3l.14-.15,72.81-3.88v-53.41c0-6.42-2.23-12.61-6.29-17.41-3.3-3.9-7.31-6.98-11.94-9.14l-54.18-26.69-13.05-19.94-38.45,12.5-12.14,18.31c-.17,.16-17.02,15.75-36.31,25.2-11.91,5.84-25.6,7.03-38.58,3.37-1.44,.64-14.29,7.43-16.09,46.27-.03,.37-2.74,34.81,9.49,50.15Z" fill="#231f20"></path>
				</g>
					<path d="M431.49,448.4c-.27,0-.49-.21-.5-.48l-1.95-61.39c.01-.16,.87-9.56-4.65-13.24-.23-.15-.29-.46-.14-.69,.15-.23,.46-.29,.69-.14,6.03,4.02,5.13,13.7,5.09,14.11l1.95,61.33c0,.28-.21,.51-.48,.52,0,0-.01,0-.02,0Z" fill="#231f20"></path>
					<path d="M430.51,424.04c-.06,0-.13-.01-.19-.04-.25-.11-.37-.4-.27-.65l7.8-18.51c.11-.26,.4-.37,.65-.27,.25,.11,.37,.4,.27,.65l-7.8,18.51c-.08,.19-.27,.31-.46,.31Z" fill="#231f20"></path>
					<path d="M375.94,478.61c-.27,0-.49-.21-.5-.48l-2.92-92.57c0-.28,.21-.51,.48-.52,.28-.03,.51,.21,.52,.48l2.92,92.57c0,.28-.21,.51-.48,.52,0,0-.01,0-.02,0Z" fill="#231f20"></path>
					<path d="M402.25,469.84c-.1,0-.2-.03-.29-.09-.23-.16-.28-.47-.12-.7,.12-.16,11.8-16.51,29.33-20.41,.27-.06,.54,.11,.6,.38,.06,.27-.11,.54-.38,.6-17.15,3.81-28.61,19.85-28.73,20.01-.1,.14-.25,.21-.41,.21Z" fill="#231f20"></path>
				<g>
					<path d="M504.57,443.03l62.36-9.74s30.21-3.9,45.8,14.62l9.24,12.33c1.28,1.7,.7,4.14-1.2,5.09h0c-1.31,.66-2.9,.4-3.93-.64l-10.79-10.79c-.65-.65-1.67-.75-2.44-.24h0c-.89,.59-1.13,1.79-.54,2.68l4.98,7.48c.9,1.35,.33,3.17-1.17,3.78h0c-1.14,.45-2.44,.04-3.09-1l-5.69-8.94s-2.52-2.15-4.67,.39l-45.02-8.18-47.75,1.95,3.9-4.87v-3.9Z" fill="#feebdd"></path>
					<path d="M605.96,468.26c-1.02,0-2-.51-2.57-1.41l-5.64-8.87c-.39-.31-2.25-1.58-3.91,.37l-.19,.22-45.25-8.22-48.79,1.99,4.47-5.59v-4.15l62.79-9.81c.32-.04,30.67-3.72,46.26,14.79l9.26,12.35c.7,.93,.94,2.11,.68,3.24-.27,1.13-1.02,2.08-2.05,2.6-1.51,.75-3.32,.46-4.51-.73l-10.79-10.79c-.48-.48-1.24-.56-1.81-.18-.32,.21-.53,.54-.61,.91-.07,.38,0,.76,.21,1.07l4.98,7.48c.51,.77,.65,1.71,.37,2.59-.27,.88-.92,1.58-1.78,1.92-.37,.15-.75,.22-1.13,.22Zm-10.11-11.97c1.44,0,2.55,.93,2.6,.97l.1,.11,5.69,8.94c.53,.83,1.57,1.17,2.49,.8,.57-.23,1.01-.7,1.19-1.29s.09-1.23-.25-1.74l-4.98-7.48c-.36-.54-.49-1.19-.36-1.83s.5-1.19,1.04-1.55c.96-.64,2.25-.51,3.07,.3l10.79,10.79c.89,.89,2.23,1.11,3.35,.54,.77-.39,1.33-1.09,1.53-1.93,.2-.84,.02-1.72-.5-2.41l-9.24-12.33c-15.21-18.07-45.04-14.46-45.34-14.42l-61.93,9.68v3.65l-3.32,4.15,46.71-1.91h.05s44.75,8.14,44.75,8.14c.68-.72,1.46-1.11,2.31-1.18,.09,0,.18-.01,.27-.01Z" fill="#231f20"></path>
				</g>
				<g>
					<polygon points="613.71 502.47 653.66 347.53 832.96 328.04 786.19 479.08 613.71 502.47" fill="#7ab6cb"></polygon>
					<path d="M613.04,503.06l40.22-155.99,.34-.04,180.06-19.57-.23,.73-46.87,151.34-.32,.04-173.22,23.49Zm41.02-155.07l-39.68,153.88,171.43-23.24,46.45-150.01-178.2,19.37Z" fill="#231f20"></path>
				</g>
				<g>
					<polygon points="493.85 482.98 608.84 495.65 648.79 340.71 822.24 324.14 832.96 328.04 653.66 347.53 613.71 502.47 492.88 489.8 493.85 482.98" fill="#231f20"></polygon>
					<path d="M614.08,503.01l-121.78-12.77,1.12-7.81,.48,.05,114.55,12.62,39.93-154.86,.35-.03,173.56-16.58,12.85,4.67-181.09,19.68-39.97,155.02Zm-120.64-13.65l119.89,12.57,39.93-154.85,.34-.04,177.16-19.26-8.59-3.12-172.99,16.52-39.97,155.02-114.93-12.66-.83,5.83Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M494.16,482.94l121.04-14.04s.04,.02,.04,.04l-6.4,26.67s-.02,.03-.04,.03l-114.64-12.63s-.04-.07,0-.07Z" fill="#e6e7e8"></path>
					<path d="M609.23,496.16l-.48-.02-115.09-12.68-.03-.93,.47-.09,121.3-14.07,.38,.4-.05,.29-6.5,27.1Zm-110.93-13.19l110.15,12.14,6.15-25.63-116.3,13.49Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M439.76,312.6s4.46,20.66-7.76,26.82c0,0,23.84,59.76,40.41,74.37,0,0,8.77-20.46,13.64-47.75l.89-17.58-24.27-37.97-22.91,2.1Z" fill="#feebdd"></path>
					<path d="M472.6,414.63l-.52-.46c-16.47-14.53-39.56-72.12-40.54-74.56l-.17-.43,.41-.21c11.73-5.92,7.54-26.07,7.49-26.27l-.12-.55,23.77-2.18,24.52,38.36-.89,17.74c-4.83,27.09-13.59,47.71-13.68,47.92l-.27,.64Zm-39.96-74.97c2.14,5.29,24.05,58.83,39.58,73.29,1.51-3.7,9.01-22.75,13.34-46.99l.87-17.35-24.02-37.58-22.05,2.02c.63,3.39,3.24,20.63-7.72,26.61Z" fill="#231f20"></path>
				</g>
				<g>
					<rect x="439.28" y="229.62" width="84.29" height="119.37" rx="42.14" ry="42.14" fill="#feebdd"></rect>
					<path d="M481.43,349.49c-23.51,0-42.65-19.13-42.65-42.65v-35.08c0-23.51,19.13-42.65,42.64-42.65s42.65,19.13,42.65,42.65v35.08c0,23.51-19.13,42.65-42.65,42.65Zm0-119.37c-22.96,0-41.65,18.68-41.65,41.65v35.08c0,22.96,18.68,41.65,41.64,41.65h0c22.96,0,41.65-18.68,41.65-41.65v-35.08c0-22.96-18.68-41.65-41.65-41.65Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M465.59,219.88s-35.11-18.01-54.28,14.35c-1.1,1.85-2.02,3.81-2.85,5.79-1.1,2.64-3.83,8.01-9.23,12.72-4.9,4.27-8.5,9.91-9.96,16.24-1.34,5.8-1.21,13.01,2.98,20.87,1.93,3.62,3.32,7.53,3.84,11.61,.63,5.02,.21,11.22-3.77,16.52-2.33,3.1-5.9,5.03-9.75,5.48-8.01,.94-24.52,4.95-19.3,22.13,0,0,11.69,22.41,22.41-.97,0,0,10.92-16.8,17.94-10.62,1.81,1.59,3.78,2.98,6.02,3.86,10.44,4.13,35.13,10.42,30.11-25.25l2.44-8.92,4.87,.97s20.46-.97,21.44-29.23c0,0-1.95-15.59,1.95-23.39,0,0,7.8-14.62,13.64-6.82l1.95,22.41s20.46-3.9,15.59-29.23c0,0,20.57,12.85,21.5,52.87,0,0,8.98-10.13,5.27-31.77-2.35-13.73-9.87-26.1-20.86-34.65-11.02-8.57-26.79-15.81-41.96-4.97Z" fill="#352828"></path>
					<path d="M374.83,355.76c-6.73,0-11.78-9.54-12-9.95-1.71-5.57-1.33-10.23,1.09-13.92,4.16-6.37,13.44-8.33,18.59-8.93,3.8-.44,7.23-2.37,9.41-5.28,3.24-4.32,4.48-9.76,3.67-16.15-.47-3.77-1.75-7.61-3.78-11.43-3.67-6.89-4.68-14.03-3.02-21.22,1.45-6.29,4.95-12,10.12-16.5,5.28-4.6,7.97-9.83,9.1-12.54,.97-2.31,1.88-4.17,2.88-5.86,18.28-30.87,51.3-16.27,54.66-14.67,15.5-10.87,31.55-3.21,42.32,5.15,11.2,8.71,18.68,21.13,21.05,34.96,3.71,21.63-5.02,31.76-5.39,32.18l-.84,.95-.03-1.27c-.79-34.23-16.22-48.55-20.29-51.74,1.32,8,.22,14.65-3.26,19.79-4.88,7.19-12.62,8.74-12.95,8.8l-.54,.1-1.98-22.82c-1.11-1.43-2.3-2.06-3.65-1.97-4.48,.34-9.02,8.75-9.07,8.83-3.78,7.56-1.91,22.94-1.89,23.09-.98,28.47-21.7,29.78-21.91,29.79h-.06s-4.48-.89-4.48-.89l-2.29,8.37c1.79,12.81-.1,21.49-5.59,25.82-7.24,5.7-18.9,2.36-25.21-.14-2.05-.81-4.07-2.1-6.17-3.95-1.14-1-2.42-1.4-3.91-1.19-6.16,.84-13.22,11.6-13.29,11.71-3.24,7.09-6.99,10.77-11.1,10.88-.06,0-.12,0-.18,0Zm69.07-140.29c-10.7,0-23.23,3.96-32.15,19.01-.97,1.64-1.87,3.46-2.82,5.73-1.16,2.79-3.93,8.17-9.36,12.91-5.01,4.36-8.39,9.89-9.8,15.97-1.61,6.95-.62,13.86,2.93,20.53,2.09,3.93,3.4,7.89,3.89,11.78,.84,6.66-.46,12.34-3.87,16.88-2.35,3.13-6.03,5.2-10.09,5.67-4.98,.58-13.93,2.46-17.87,8.49-2.25,3.43-2.58,7.8-1,13,.01,0,4.95,9.32,11.08,9.32,.05,0,.1,0,.16,0,3.69-.1,7.14-3.58,10.25-10.36,.33-.52,7.42-11.31,14.03-12.22,1.77-.24,3.35,.24,4.7,1.43,2.01,1.77,3.93,3,5.87,3.77,6.11,2.42,17.38,5.67,24.22,.28,5.21-4.1,6.96-12.51,5.21-25v-.1s2.58-9.47,2.58-9.47l5.27,1.05c1.43-.09,19.97-1.79,20.9-28.75-.08-.56-1.92-15.75,2-23.59,.2-.38,4.85-8.99,9.88-9.37,1.74-.13,3.28,.7,4.61,2.48l.09,.11,1.91,21.98c1.42-.38,5.36-1.65,8.87-4.89,5.82-5.37,7.76-13.32,5.78-23.62l-.22-1.13,.97,.61c.21,.13,20.31,13.13,21.69,51.82,2.1-3.19,7.26-13.09,4.32-30.21-2.33-13.58-9.67-25.78-20.68-34.34-10.55-8.2-26.29-15.72-41.37-4.96l-.25,.18-.27-.14c-.18-.09-9.73-4.85-21.47-4.85Z" fill="#231f20"></path>
				</g>
				<g>
					<ellipse cx="438.83" cy="300.76" rx="9.23" ry="12.67" fill="#fff"></ellipse>
					<path d="M438.83,313.93c-5.36,0-9.73-5.91-9.73-13.17s4.36-13.17,9.73-13.17,9.73,5.91,9.73,13.17-4.36,13.17-9.73,13.17Zm0-25.34c-4.81,0-8.73,5.46-8.73,12.17s3.91,12.17,8.73,12.17,8.73-5.46,8.73-12.17-3.91-12.17-8.73-12.17Z" fill="#231f20"></path>
				</g>
				<g>
					<path d="M415.89,489.8s52.97,0,93.57-15.43c1.85-.7,3.84-.95,5.81-.75,5.19,.54,16.51,1,27.3-3.31l14.14,12.57c1.91,1.7,4.81,1.61,6.61-.19h0c1.96-1.96,1.87-5.16-.19-7.01l-11.84-10.6c-1.75-1.56-1.01-4.45,1.28-4.97,2.14-.49,5.04-.21,8.52,2.4l15.71,17.37c1.64,1.82,4.32,2.27,6.47,1.1l13.43-7.32c2.56-1.4,3.51-4.59,2.13-7.16-5.41-10.09-21.58-32.03-57.23-24.44l-37.03,4.87-73.08,3.9s-13.64,13.64-15.59,38.98Z" fill="#feebdd"></path>
					<path d="M415.89,490.3h-.54l.04-.54c1.94-25.23,15.6-39.16,15.74-39.29l.14-.14,73.28-3.91,36.99-4.87c36.03-7.67,52.38,14.72,57.74,24.7,1.5,2.8,.46,6.31-2.33,7.83l-13.43,7.33c-2.36,1.29-5.27,.79-7.08-1.2l-15.71-17.37c-2.75-2.05-5.48-2.83-8.04-2.25-.88,.2-1.55,.85-1.78,1.73-.23,.88,.04,1.77,.72,2.38l11.84,10.6c1.1,.99,1.73,2.34,1.77,3.82s-.51,2.87-1.56,3.91c-1.99,1.99-5.2,2.08-7.3,.21l-13.91-12.37c-10.63,4.17-21.62,3.82-27.25,3.23-1.9-.2-3.83,.05-5.58,.72-40.22,15.28-93.22,15.46-93.75,15.46Zm15.82-38.99c-1.38,1.47-13.32,14.8-15.27,37.99,5.71-.06,54.99-1,92.85-15.39,1.9-.72,3.99-.99,6.04-.78,5.59,.58,16.54,.93,27.06-3.28l.29-.11,14.37,12.78c1.71,1.52,4.31,1.44,5.93-.17,.85-.85,1.3-1.98,1.26-3.18-.03-1.2-.54-2.3-1.44-3.1l-11.84-10.6c-.95-.85-1.34-2.14-1.02-3.37,.32-1.23,1.28-2.17,2.52-2.45,2.86-.65,5.86,.18,8.93,2.49l15.78,17.43c1.49,1.65,3.9,2.06,5.86,.99l13.43-7.33c2.31-1.26,3.17-4.17,1.93-6.48-5.25-9.78-21.27-31.73-56.69-24.19l-37.07,4.88-72.93,3.89Z" fill="#231f20"></path>
				</g>
					<path d="M586.42,478.61c-.14,0-.28-.06-.38-.17l-18.51-21.44c-.18-.21-.16-.52,.05-.71,.21-.18,.52-.16,.71,.05l18.51,21.44c.18,.21,.16,.52-.05,.71-.09,.08-.21,.12-.33,.12Z" fill="#231f20"></path>
					<path d="M594.01,475.06c-.15,0-.3-.07-.4-.2l-16.36-21.79c-.17-.22-.12-.53,.1-.7,.22-.16,.54-.12,.7,.1l16.36,21.79c.17,.22,.12,.53-.1,.7-.09,.07-.2,.1-.3,.1Z" fill="#231f20"></path>
				<g>
					<path d="M215.09,246.31c-.54,.98-3.89,7.86-.18,22.93,1.69,6.85,4.41,13.4,7.97,19.49,3.36,5.76,9.11,16.85,14.09,32.48,1.37,4.3,2.39,8.71,3.03,13.18,.81,5.73,2.95,15.32,8.31,18,0,0-12.67,6.82-16.57-4.87,0,0-5.85-23.39-13.64-36.05,0,0-30.11-49.54-3.05-65.21,.03-.02,.08,.01,.06,.05Z" fill="#fff"></path>
					<path d="M240.64,354.89c-1.26,0-2.56-.19-3.8-.68-2.61-1.03-4.49-3.23-5.58-6.51-.07-.27-5.93-23.49-13.59-35.95-.19-.31-18.73-31.15-13.45-51.83,1.58-6.19,5.14-10.93,10.58-14.08l.33-.19,.6,.54-.18,.38c-.4,.73-3.83,7.54-.14,22.57,1.64,6.64,4.3,13.16,7.91,19.36,3.1,5.31,9.05,16.61,14.13,32.58,1.39,4.38,2.42,8.85,3.04,13.26,.77,5.47,2.85,15.03,8.03,17.62l.86,.43-.85,.46c-.2,.11-3.85,2.04-7.89,2.04Zm-26.62-107.41c-4.49,2.99-7.46,7.25-8.85,12.68-5.17,20.27,13.15,50.75,13.34,51.06,7.75,12.59,13.64,35.96,13.7,36.19,.99,2.96,2.67,4.94,4.99,5.86,3.72,1.48,8.2-.12,10.02-.92-4.97-3.33-6.97-12.52-7.73-17.88-.62-4.36-1.63-8.77-3.01-13.1-5.05-15.88-10.97-27.11-14.04-32.38-3.66-6.29-6.36-12.89-8.02-19.63-2.98-12.12-1.47-19.01-.4-21.88Zm.63-1.41h0Z" fill="#231f20"></path>
				</g>
			</g>
			<g>
				<path d="M465.78,289.69c-.13,0-.26-.05-.35-.15-.2-.2-.2-.51,0-.71,.28-.28,6.87-6.75,16.87-6.75,.28,0,.5,.22,.5,.5s-.22,.5-.5,.5c-9.59,0-16.1,6.4-16.16,6.46-.1,.1-.23,.15-.35,.15Z" fill="#231f20"></path>
				<path d="M516.98,278.13c-.08,0-.15-.02-.22-.05-6.3-3.15-12.7-.03-12.77,0-.25,.12-.55,.02-.67-.22s-.02-.55,.22-.67c.28-.14,6.91-3.38,13.66,0,.25,.12,.35,.42,.22,.67-.09,.18-.26,.28-.45,.28Z" fill="#231f20"></path>
				<path d="M474.04,297.95c-.21,0-.4-.13-.47-.34-.09-.26,.05-.55,.32-.63l9.91-3.3c.27-.09,.54,.05,.63,.32,.09,.26-.05,.55-.32,.63l-9.91,3.3c-.05,.02-.11,.03-.16,.03Z" fill="#231f20"></path>
				<path d="M516.98,291.34h-9.91c-.28,0-.5-.22-.5-.5s.22-.5,.5-.5h9.91c.28,0,.5,.22,.5,.5s-.22,.5-.5,.5Z" fill="#231f20"></path>
				<path d="M497.16,314.47c-.21,0-.4-.13-.47-.34-.09-.26,.05-.55,.32-.63l9.41-3.14-6.41-16.03c-.1-.26,.02-.55,.28-.65,.25-.1,.55,.02,.65,.28l6.61,16.52c.05,.13,.05,.27-.01,.4s-.17,.22-.3,.26l-9.91,3.3c-.05,.02-.11,.03-.16,.03Z" fill="#231f20"></path>
				<g>
					<path d="M477.34,320.57h23.13s-6.61,26.43-23.13,0Z" fill="#fff"></path>
					<path d="M490.68,332.82c-4.16,0-8.79-4.02-13.76-11.98l-.48-.77h24.67l-.16,.62c-.11,.45-2.83,11.03-9.28,12.05-.33,.05-.66,.08-.99,.08Zm-12.43-11.75c4.95,7.7,9.4,11.28,13.27,10.68,4.96-.78,7.61-8.43,8.29-10.68h-21.56Z" fill="#231f20"></path>
				</g>
			</g>
			<path d="M438.37,305.4c-.28,0-.5-.22-.5-.5v-7.8c0-.28,.22-.5,.5-.5s.5,.22,.5,.5v7.8c0,.28-.22,.5-.5,.5Z" fill="#231f20"></path>
		</svg>
  );
};
